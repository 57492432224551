:root,
::after,
::before {
  /* Insights hub standard sizes */
  --yt-size-s: 1px;
  --yt-size-m: 4px;
  --yt-size-ml: 8px;
  --yt-size-l: 12px;
  --yt-size-xl: 16px;
  --yt-size-2xl: 20px;
  --yt-size-3xl: 24px;
  --yt-size-4xl: 28px;
  --yt-size-5xl: 32px;
  --yt-size-6xl: 36px;
  --yt-size-7xl: 40px;
  --yt-size-8xl: 44px;
  --yt-size-9xl: 48px;
  --yt-size-10xl: 52px;
  --yt-size-11xl: 56px;
  --yt-size-12xl: 60px;

  /* Insights hub extra large styles for cards, modals, etc.  */

  --yt-size-30xl: 200px;
  --yt-size-40xl: 400px;
  --yt-size-50xl: 500px;
  --yt-size-90xl: 600px;
  --yt-size-120xl: 800px;
  --yt-size-150xl: 1000px;
  --yt-size-viewport-width: 100vw;
  --yt-size-viewport-height: 100vh;

  /* Insights hub colors */

  --yt-neutral-grey-2: #393939;
  --yt-neutral-grey-3: #c4c4c4;
  --yt-neutral-grey-4: #dcdcdc;
  --yt-neutral-grey-7: #fcfcfc;
  --yt-white: #fff;
  --yt-red: #fe0000;
  --yt-dark: #1c1a1a;
  --yt-neutral: #c6c2c2;
  --yt-link: #28b1f5;
  --yt-categorical-palette-green: #b9c081;

  /* Responsive design breakpoints */
  --yt-sm: 640px;
  --yt-md: 768px;
  --yt-lg: 1024px;
  --yt-xl: 1280px;
  --yt-2xl: 1536px;
}

* {
  margin: 0;
  padding: 0;
  font-family: Open Sans;
}

body {
  overflow-x: hidden;
}
